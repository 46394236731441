import React from "react"
import { graphql } from "gatsby"

import Post from "./post"

const PostContainer = ({ data }) => <Post data={data} />
export default PostContainer

export const query = graphql`
  query post($id: String!, $nextPage: String, $previousPage: String) {
    post: wpPost(id: { eq: $id }) {
      title
      content
      date
      slug
      featuredImage {
        node {
          localFile {
            ...HeroImage
          }
        }
      }
      tags {
        nodes {
          databaseId
        }
      }
      acfRecipe {
        difficulty
        expiration
        freezing
        introduction
        poulainstouch
        authorship {
          name
          role
        }
        cookingtime {
          hours
          minutes
        }
        ingredients {
          issubtitle
          description
          fraction
          precisequantity
          preciseunity
          quantity
          steps
          unity
        }
        maintag {
          name
          id
        }
        servings {
          label
          value
        }
        servingsvariations {
          multiplier
          value
        }
        steps {
          description
          image {
            altText
            localFile {
              ...StepImage
            }
          }
        }
        totaltime {
          hours
          minutes
        }
        gallery {
          altText
          localFile {
            ...StepImage
          }
        }
      }
    }

    nextPost: wpPost(id: { eq: $nextPage }) {
      title
      uri
    }

    previousPost: wpPost(id: { eq: $previousPage }) {
      title
      uri
    }
  }
`
